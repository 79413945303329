import { postInfo } from './api';

export default {
  // 插入角色
  insertRole(data) {
    return postInfo('/role/insertRole', data);
  },
  // 获取角色列表
  getRoleList(data) {
    return postInfo('/role/getRoleList', data);
  },
  // 更新角色
  updateRole(data) {
    return postInfo('/role/updateRole', data);
  },
  // 删除角色
  deleteRole(data) {
    return postInfo('/role/deleteRole', data);
  },
  // 获取角色的所有权限
  getPermissionListByRoleId(data) {
    return postInfo('/role/getPermissionListByRoleId', data);
  },
  // 为角色绑定权限
  roleBindPermission(data) {
    return postInfo('/role/roleBindPermission', data);
  },
};
