<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="角色名称"
          prop="name"
        >
          <el-input
            placeholder="角色名称"
            v-model.trim="formData.name"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="角色标识"
          prop="sign"
        >
          <el-input
            placeholder="角色标识"
            v-model.trim="formData.sign"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="角色类型"
          prop="roleType"
        >
          <el-select
            v-model="formData.roleType"
          >
            <el-option
              v-for="(item, index) in filterDictionary('0101')"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="角色状态"
          prop="status"
        >
          <el-select
            v-model="formData.status"
          >
            <el-option
              v-for="(item, index) in filterDictionary('0102')"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import utils from '@/utils/utils';
import api from '@/api/RoleManageApi';

export default {
  name: 'AddRoleManage',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '新增角色',
      loading: false,
      formData: {
        name: '',
        sign: '',
        roleType: '',
        status: '',
      },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        sign: [{ required: true, message: '请输入', trigger: 'change' }],
        roleType: [{ required: true, message: '请选择', trigger: 'change' }],
        status: [{ required: true, message: '请选择', trigger: 'change' }],
      },
    };
  },
  watch: {},
  methods: {
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            id,
            name,
            roleType,
            sign,
            status,
          } = this.formData;
          if (this.dialogTitle === '新增角色') {
            api.insertRole(
              {
                name,
                roleType,
                sign,
                status,
              },
            )
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            api.updateRole(
              {
                id,
                name,
                roleType,
                sign,
                status,
              },
            )
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
