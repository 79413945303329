<template>
  <div class="container">
    <el-dialog
      v-drag
      :title="title"
      :visible.sync="DialogVisible"
      left
      width="500px"
      @closed="closeDialog('form')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-tree
        ref="tree"
        :data="tableData"
        show-checkbox
        node-key="id"
        default-expand-all
        :default-checked-keys="checkedKeysId"
        :props="defaultProps"
      >
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="sure"
          size="small"
          v-loading.fullscreen.lock="fullscreenLoading"
          element-loading-text="正在保存，请稍后..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/RoleManageApi';

export default {
  name: 'RoleAuthDialog',
  components: {},
  data() {
    return {
      title: '绑定权限',
      DialogVisible: false,
      fullscreenLoading: false,
      roleId: '',
      // 表格数据
      tableData: [],
      checkedKeysId: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    getPermissionListByRoleId(id) {
      this.roleId = id;
      api.getPermissionListByRoleId(
        {
          id,
        },
      )
        .then(res => {
          const resData = JSON.parse(JSON.stringify(res.result));
          this.tableData = this.formaterTableData(resData);
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 排序后的菜单
    formaterTableData(datas) {
      const data = this.menusSort(datas);
      data.forEach(val => {
        val.checked = val.checked.includes('true');
        if (val.checked && !val.children) {
          this.checkedKeysId.push(val.id);
        }
        if (val.children) {
          this.formaterTableData(val.children);
        }
      });
      return data;
    },
    // 菜单排序
    menusSort(data) {
      const res = data.sort((val1, val2) => Number(val1.id) - Number(val2.id));
      return res;
    },
    sure() {
      const data = this.$refs.tree.getCheckedNodes();
      const permissionIdList = [];
      data.forEach(val => {
        permissionIdList.push(val.id);
      });
      this.fullscreenLoading = true;
      api.roleBindPermission({
        permissionIdList,
        roleId: this.roleId,
      }).then(() => {
        this.DialogVisible = false;
        this.$message.success('绑定成功！');
        this.$emit('refreshTable');
      }).catch(err => {
        console.log(err);
        this.$message.error(err.message);
      })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    closeDialog() {
      this.tableData = [];
      this.checkedKeysId = [];
    },
  },
};
</script>
