<template>
  <div class="valuation">
    <l-toggle :FormButton="FormButton">
      <el-form
        ref="elfrom"
        :model="searchFrom"
        size="small"
        label-width="90px"
      >
        <el-row>
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="角色名称">
              <el-input
                v-model.trim="searchFrom.user"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="角色标识">
              <el-input
                v-model.trim="searchFrom.sign"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="角色状态">
              <el-select
                v-model="searchFrom.status"
                filterable
                clearable
              >
                <el-option
                  v-for="(item, index) in filterDictionary('0102')"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="角色类型">
              <el-select
                v-model="searchFrom.roleType"
                filterable
                clearable
              >
                <el-option
                  v-for="(item, index) in filterDictionary('0101')"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </l-toggle>
    <div class="tableBox">
      <el-table
        ref="eltable"
        :data="tableData"
        :header-cell-style="{background:'#f2f2f2'}"
        height="100%"
        stripe
        border
        v-loading="tableLoading"
      >
        <template slot="empty">
          <img src="@/assets/img/table_noData.png" width="300" height="300" />
        </template>
        <el-table-column type="selection" width="50" align="center"></el-table-column>

        <el-table-column prop="name" label="角色名称" align="center"></el-table-column>
        <el-table-column prop="sign" label="角色标识" align="center"></el-table-column>
        <el-table-column prop="roleType" label="角色类型" align="center">
          <template slot-scope="scope">
            {{ valueToName(filterDictionary('0101'), scope.row.roleType) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="角色状态" align="center">
          <template slot-scope="scope">
            {{ valueToName(filterDictionary('0102'), scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" width="150"></el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
            >编辑
            </el-button>
            <el-button
              type="success"
              icon="el-icon-paperclip"
              size="mini"
              @click="bindAuth(scope.row)"
            >绑定权限
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delData(scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px;"
        background
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
    <!-- 新增层 -->
    <add-role-manage
      ref="AddRoleManage"
      @refreshTable="refreshTable"
    ></add-role-manage>
    <!-- 绑定权限层 -->
    <role-auth
      ref="RoleAuth"
      @refreshTable="refreshTable"
    ></role-auth>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import AddRoleManage from './RoleManage/AddRoleManage.vue';
import RoleAuth from './RoleManage/RoleAuth.vue';
import api from '@/api/RoleManageApi';

export default {
  name: 'RoleManage',
  components: {
    LToggle,
    AddRoleManage,
    RoleAuth,
  },
  data() {
    return {
      searchFrom: {
        name: '',
        sign: '',
        status: '',
        roleType: '',
      },
      FormButton: [
        {
          icon: 'el-icon-search',
          name: '查询',
          type: 'primary',
          loading: false,
          click: this.searchEvent,
        },
        {
          icon: 'el-icon-plus',
          name: '新增',
          type: 'primary',
          click: this.addEvent,
        },
      ],
      tableLoading: false,
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 0,
      },

    };
  },
  watch: {
    tableLoading(nval) {
      this.FormButton[0].loading = nval;
    },
  },
  created() {
    this.reqTableData();
  },
  methods: {
    delData(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            const { id } = row;
            api.deleteRole({
              id,
            })
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    bindAuth(row) {
      this.$refs.RoleAuth.DialogVisible = true;
      this.$refs.RoleAuth.getPermissionListByRoleId(row.id);
    },
    edit(row) {
      this.$refs.AddRoleManage.dialogVisible = true;
      this.$refs.AddRoleManage.dialogTitle = '编辑角色';
      this.$nextTick(() => {
        this.$refs.AddRoleManage.formData = { ...row };
      });
    },
    searchEvent() {
      this.reqTableData();
    },
    addEvent() {
      this.$refs.AddRoleManage.dialogVisible = true;
      this.$refs.AddRoleManage.dialogTitle = '新增角色';
    },
    refreshTable() {
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    reqTableData() {
      this.tableLoading = true;
      const {
        name,
        roleType,
        sign,
        status,
      } = this.searchFrom;
      const {
        pageNum,
        pageSize,
      } = this.pageInfo;
      api.getRoleList(
        {
          name,
          roleType,
          sign,
          status,
          pageNum,
          pageSize,
        },
      )
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
